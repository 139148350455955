<template>
  <div class="company-bank-container">
    <div class="top-bar">
      <h3>{{title}}</h3>
    </div>
    <el-divider class="el-divider-border"/>
    <el-form :model="bankInfoForm"  :ref="refKey" :validate-on-rule-change="false">
      <div class="tool-bar" v-if="!isViewOnly">
        <span
          class="second-button"
          @click="addNewLine('COMPANY_BANK')">
          Add Bank Account
        </span>
      </div>
      <div class="table-container">
        <el-table class="el-table-bank-info"
        :data="bankInfoForm.bankAccountList"
        :header-cell-style="{ background: '#F4F6FA' }"
        :cell-style="{alignSelf: 'center'}"
        @current-change = 'handleCurrentChange'
        >
          <el-table-column width="55" v-if="hasRadio">
            <template v-slot="scope">
              <el-radio :label="scope.row.id" v-model="defaultRadioItem" @change="handleBankRadioSelectChange($event, refKey)"><i/></el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="name"   >
            <template class="table-header" v-slot:header><span>* </span>Name of Bank</template>
            <template v-slot="scope">
              <el-form-item :prop="`bankAccountList.${scope.$index}.name`" :rules="rule">
                <el-input  class="table_input "
                  v-model="scope.row.name"
                  @change="handleChange(scope.row,scope.$index)"
                  :readonly="isViewOnly"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="swiftCode" >
            <template class="table-header" v-slot:header><span>* </span>Swift Code</template>
            <template v-slot="scope">
              <el-form-item :prop="`bankAccountList.${scope.$index}.swiftCode`" :rules="rule">
                <el-input  class="table_input "
                  v-model="scope.row.swiftCode"
                  @change="handleChange(scope.row,scope.$index)"
                  :readonly="isViewOnly"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="accountName" >
            <template class="table-header" v-slot:header><span v-if="category ==='COMPANY_BANK'">* </span>Account Name</template>
            <template v-slot="scope">
              <el-form-item :prop="`bankAccountList.${scope.$index}.accountName`" :rules="category ==='COMPANY_BANK' ? rule : { required: false, trigger: 'blur'}">
                <el-input  class="table_input "
                 style="outline:none"
                 v-model="scope.row.accountName"
                 @change="handleChange(scope.row,scope.$index)"
                 :readonly="isViewOnly"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="accountNo" >
            <template class="table-header" v-slot:header><span v-if="category ==='COMPANY_BANK'">* </span>Account Number</template>
            <template v-slot="scope">
              <el-form-item :prop="`bankAccountList.${scope.$index}.accountNo`" :rules="category ==='COMPANY_BANK' ? rule : { required: false, trigger: 'blur'}">
                <el-input  class="table_input "
                 style="outline:none"
                 v-model="scope.row.accountNo"
                 @change="handleChange(scope.row,scope.$index)"
                 :readonly="isViewOnly"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="accountType" >
            <template class="table-header" v-slot:header>Account Type</template>
            <template v-slot="scope">
                <el-input  class="table_input "
                  style="outline:none"
                  v-model="scope.row.accountType"
                  @change="handleChange(scope.row,scope.$index)"
                  :readonly="isViewOnly"
                />
            </template>
          </el-table-column>
          <el-table-column prop="country" >
            <template class="table-header" v-slot:header><span>* </span>Country</template>
            <template v-slot="scope">
              <el-form-item :prop="`bankAccountList.${scope.$index}.country`" :rules="rule">
                <el-input  class="table_input "
                  v-model="scope.row.country"
                  @change="handleChange(scope.row,scope.$index)"
                  :readonly="isViewOnly"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column class="table-header" label="Default" v-if="!isViewOnly">
            <template v-slot="scope">
                <el-radio
                @change="handleChange(scope.row,scope.$index)"
                v-model="defaultRadioItem"   :label="scope.row.id"><span></span></el-radio>
            </template>
          </el-table-column>
          <el-table-column class="table-header last-child-cell flex-between-row" label="Actions" v-if="!isViewOnly">
            <template v-slot="scope">
                <el-button
                 class="border-radius"
                  type="success"  size="mini"
                  :disabled="!($_.includes(changedRow,scope.row.id))"
                  @click="saveLine(scope.row, scope.$index)">Save</el-button
                >
                <template>
                  <el-popconfirm
                    class="btn-delete"
                    title="Are you sure to delete this?"
                    @confirm="removeLine(scope.row, scope.$index)">
                    <el-button class="border-radius" slot="reference" type="danger" size="mini" >Delete</el-button>
                  </el-popconfirm>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div v-if="hasButtons" style="text-align: right; margin-top: 20px;">
      <el-button
        round type="primary"
        @click="handleConfirmBankClick"
      >
        Confirm
      </el-button>
      <el-button
        outline
        round
        @click="handleCloseBankDialog"
      >
        Cancel
      </el-button>
    </div>
  </div>
</template>
<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import { rule } from '@/constants.js'
export default {
  name: 'UpdateBank',
  mixins: [baseMixin, baseTableMixin],
  props: {
    refKey: String,
    category: String,
    sysOrganizationId: String,
    data: Array,
    companyDefaultBank: Object,
    chaserDefaultBank: Object,
    isViewOnly: Boolean,
    hasRadio: Boolean,
    hasButtons: Boolean
  },
  data () {
    const bankItemLine = {
      name: null,
      swiftCode: null,
      accountName: null,
      accountNo: null, // Mandatory if bankType is COMPANY_BANK
      accountType: null,
      country: null,
      isDefault: false, // Backend default to false,
      sysOrganizationId: this.sysOrganizationId,
      bankType: this.category
    }
    const rules = {
      name: rule,
      swiftCode: rule,
      country: rule,
      accountName: rule,
      accountNo: rule
    }
    // const bankInfoForm = {
    //   bankType: null,
    //   bankAccountList: null
    // }
    return {
      bankItemLine,
      rule,
      rules,
      defaultRadioItem: null,
      title: null,
      bankAccountList: [],
      changedRowIndex: [],
      markIndex: null,
      changedRow: [],
      changedLineId: null
    }
  },
  computed: {
    bankInfoForm () {
      return { bankType: this.category, bankAccountList: this.bankAccountList }
    }
  },
  created () {
    this.createNewUrl = this.$apis.banksUrl
    this.saveUrl = this.$apis.banksUrl
    this.deleteUrl = this.$apis.banksUrl
    this.getAllUrl = this.$apis.banksUrl
  },
  mounted () {
    this.getInitData()
  },
  methods: {
    getInitData () {
      if (this.data && this.data.length > 0) {
        this.bankAccountList = this.data
      }
      if (this.category === 'COMPANY_BANK') {
        this.title = 'Company Bank Account Information'
        this.defaultRadioItem = this?.companyDefaultBank?.id ?? ''
      } else {
        this.title = 'Chaser Bank Account Information'
        this.defaultRadioItem = this?.chaserDefaultBank?.id ?? ''
      }
      this.bankInfoForm.bankType = this.category
      this.bankInfoForm.bankAccountList = this.bankAccountList
    },
    addNewLine () {
      const newLine = this.$_.cloneDeep(this.bankItemLine)
      newLine.id = Date.now().toString()
      this.bankAccountList.push(newLine)
    },
    removeLineCallBack (message) {
      if (message === 'success') {
        const index = this.bankAccountList.findIndex(bank => bank.id * 1 === this.changedLineId * 1)
        this.bankAccountList.splice(index, 1)
      } else {
        // 失败回调
      }
    },
    removeLine (line, index) {
      this.changedLineId = line.id
      if (line.createdAt) {
        this.deleteSingleDataByIdMixin(line.id, this.removeLineCallBack)
      } else {
        this.bankAccountList.splice(index, 1)
      }
    },
    getAllCallBack (data) {
      this.bankAccountList = data.filter(bank => bank.bankType === this.category)
      this.bankAccountList.forEach(bank => {
        if (bank.isDefault) {
          this.defaultRadioItem = bank.id
        }
      })
    },
    saveNewCallback (message) {
      this.saveCallback(message)
      this.getAllMixin(this.getAllCallBack)
    },
    saveCallback (message) {
      if (message === 'success') {
        this.changedRow = this.changedRow.filter(bank => bank * 1 !== this.changedLineId * 1)
      } else {
        // 失败回调
      }
    },
    saveLine (line, index) {
      this.changedLineId = line.id
      const reqFields = ['name', 'swiftCode', 'country']
      this.category === 'COMPANY_BANK' && reqFields.push('accountNo')
      reqFields.forEach(reqField => {
        this.$refs[this.refKey].validateField(`bankAccountList.${index}.${reqField}`)
      })
      if (this.$refs[this.refKey].fields.find(f => f.validateState === 'error')) {
        this.$message.error('Error. Required fields not filled in.')
      } else {
        if (line.id === this.defaultRadioItem) {
          line.isDefault = true
        } else {
          line.isDefault = false
        }
        const data = { ...line }
        if (line.createdAt) {
          this.editSingleDataMixin(data, this.saveCallback)
        } else {
          delete data.id
          this.createNewSingleDataMixin(data, this.saveNewCallback)
        }
      }
    },
    handleConfirmBankClick () {
      this.$emit('handleConfirmBankClick', this.defaultRadioItem)
    },
    handleCloseBankDialog () {
      this.$emit('handleCloseBankDialog')
    },
    handleSelectChange (row) {
      // this.defaultRadioItem = curr.accountNo
    },
    handleCurrentChange (curr, prev) {
    },
    handleChange (row) {
      !this.changedRow.includes(row.id) && this.changedRow.push(row.id)
    },
    handleBankRadioSelectChange (e, refKey) {
      this.$emit('handleBankRadioSelectChange', e, refKey)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../index.scss";
.company-bank-container {
  margin: 0px 20px 20px 20px;
}
.top-bar{
  h3 {
    margin: 0 0;
  }
}
.tool-bar{
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.el-table-bank-info{
   border-right: 1px solid $primary-color-border !important;
  width:100%;
  /deep/.el-table__header th{
    border-top: 1px solid $primary-color-border;
    border-bottom: 1px solid $primary-color-border;
    font-weight: normal;
    &>div span {
      color: #D35333;
    }
  }
  /deep/.el-table__cell{
   padding: 0 ;
   margin: 0 ;
   text-align: center;
   height: 50px;
   border-left: 1px solid $primary-color-border;
   border-collapse: separate;
  }
  /deep/.el-form-item{
   padding: 0 ;
   margin: 0 ;
  }
  // for error msg
  /deep/.el-form-item__error{
   margin-top:-15px ;
  }

  .btn-delete{
     padding-left: 10px;
   }
}

</style>
